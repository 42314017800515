import { ITrafficSign } from "@drivingo/models";

const roadWorksSigns: ITrafficSign[] = [
    { imgPath: 'road-work-signs/road-work-sign-road-works.jpg', desc: "Road works" },
    { imgPath: 'road-work-signs/road-work-sign-loose-chippings.jpg', desc: "Loose chippings" },
    { imgPath: 'road-work-signs/road-work-sign-temporary-hazard.jpg', desc: "Temporary hazard at road works" },
    { imgPath: 'road-work-signs/road-work-sign-temporary-lane-closure.jpg', desc: "Temporary lane closure (the number and position of arrows and red bars may be varied according to lanes open and closed)" },
    { imgPath: 'road-work-signs/road-work-sign-back-vehicle-direction-arrow.jpg', desc: "Slow-moving or stationary works vehicle blocking a traffic lane. Pass in the direction shown by the arrow." },
    { imgPath: 'road-work-signs/road-work-sign-mandatory-speed-limit.jpg', desc: "Mandatory speed limit ahead" },
    { imgPath: 'road-work-signs/road-work-sign-road-works-ahead.jpg', desc: "Road works 1 mile ahead" },
    { imgPath: 'road-work-signs/road-work-sign-end-road-works.jpg', desc: "End of road works and any temporary restrictions including speed limits" },
    { imgPath: 'road-work-signs/road-work-sign-back-vehicle-800-yards.jpg', desc: "Signs used on the back of slow-moving or stationary vehicles warning of a lane closed ahead by a works vehicle. There are no cones on the road." },
    { imgPath: 'road-work-signs/road-work-sign-back-vehicle450-yards.jpg', desc: "Signs used on the back of slow-moving or stationary vehicles warning of a lane closed ahead by a works vehicle. There are no cones on the road." },
    { imgPath: 'road-work-signs/road-work-sign-lane-restriction.jpg', desc: "Lane restrictions at road works ahead" },
    { imgPath: 'road-work-signs/road-work-sign-lane-crossover.jpg', desc: "One lane crossover at contraflow road works" }
];

export default roadWorksSigns;
