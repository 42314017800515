import { ITrafficSign } from "@drivingo/models";

const directionSigns: ITrafficSign[] = [
    { imgPath: 'direction-signs/direction-sign-blue-junction-leading-to-motorway.jpg', desc: "At a junction leading directly into a motorway (junction number may be shown on a black background)" },
    { imgPath: 'direction-signs/direction-sign-blue-approach-to-junction.jpg', desc: "On approaches to junctions (junction number on black background)" },
    { imgPath: 'direction-signs/direction-sign-blue-route-sign.jpg', desc: "Route confirmatory sign after junction" },
    { imgPath: 'direction-signs/direction-sign-blue-get-in-lane.jpg', desc: "Downward pointing arrows mean ‘Get in lane’ The left-hand lane leads to a different destination from the other lanes." },
    { imgPath: 'direction-signs/direction-sign-blue-destination-leaving-motorway.jpg', desc: "The panel with the inclined arrow indicates the destinations which can be reached by leaving the motorway at the next junction" },
    { imgPath: 'direction-signs/direction-sign-green-approach-junction-roundabout.jpg', desc: "On approaches to junctions" },
    { imgPath: 'direction-signs/direction-sign-green-at-junction.jpg', desc: "At the junction" },
    { imgPath: 'direction-signs/direction-sign-green-route-sign.jpg', desc: "Route confirmatory sign after junction" },
    { imgPath: 'direction-signs/direction-sign-green-approach-junction.jpg', desc: "On approaches to junctions" },
    { imgPath: 'direction-signs/direction-sign-green-billingual.jpg', desc: "On approach to a junction in Wales (bilingual)" },
    { imgPath: 'direction-signs/direction-sign-green-ring-road-crossroads.jpg', desc: "Primary route forming part of a ring road" },
    { imgPath: 'direction-signs/direction-sign-green-primary-route-ring-road.jpg', desc: "Primary route forming part of a ring road" },
    { imgPath: 'direction-signs/direction-sign-black-border-approach-junction-crossroads.jpg', desc: "On approaches to junctions" },
    { imgPath: 'direction-signs/direction-sign-black-border-approach-junction.jpg', desc: "On approaches to junctions" },
    { imgPath: 'direction-signs/direction-sign-black-border-at-junction.jpg', desc: "At the junction" },
    { imgPath: 'direction-signs/direction-sign-black-border-toilets-disabled-access.jpg', desc: "Direction to toilets with access for the disabled" },
    { imgPath: 'direction-signs/direction-sign-other-picnic-site.jpg', desc: "Picnic site" },
    { imgPath: 'direction-signs/direction-sign-other-english-heritage.jpg', desc: "Ancient monument in the care of English Heritage" },
    { imgPath: 'direction-signs/direction-sign-other-direction-car-park.jpg', desc: "Direction to a car park" },
    { imgPath: 'direction-signs/direction-sign-other-tourist-attraction.jpg', desc: "Tourist attraction" },
    { imgPath: 'direction-signs/direction-sign-other-camping-caravan.jpg', desc: "Direction to camping and caravan site" },
    { imgPath: 'direction-signs/direction-sign-other-advisory-route-lorries.jpg', desc: "Advisory route for lorries" },
    { imgPath: 'direction-signs/direction-sign-other-route-pedal-cycles.jpg', desc: "Route for pedal cycles forming part of a network" },
    { imgPath: 'direction-signs/direction-sign-other-recommended-route-cycle.jpg', desc: "Recommended route for pedal cycles to place shown" },
    { imgPath: 'direction-signs/direction-sign-other-route-pedestrians.jpg', desc: "Route for pedestrians" },
    { imgPath: 'direction-signs/direction-sign-other-emergency-diversion-square.jpg', desc: "Symbols showing emergency diversion route for motorway and other main road traffic" },
    { imgPath: 'direction-signs/direction-sign-other-emergency-diversion-triangle.jpg', desc: "Symbols showing emergency diversion route for motorway and other main road traffic" },
    { imgPath: 'direction-signs/direction-sign-other-emergency-diversion-diamond.jpg', desc: "Symbols showing emergency diversion route for motorway and other main road traffic" },
    { imgPath: 'direction-signs/direction-sign-other-emergency-diversion-circle.jpg', desc: "Symbols showing emergency diversion route for motorway and other main road traffic" },
    { imgPath: 'direction-signs/direction-sign-other-diversion-route.jpg', desc: "Diversion route" }
];

export default directionSigns;
