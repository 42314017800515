export enum TestType {
    LearnPractice,
    QuickTest,
    MockTest
}

export enum TestCategory {
    topic,
    // random,
    // incorrects,
    // unanswered,
    // incorrectAndUnanswered,
    // videos
}
