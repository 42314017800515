import warningSigns from "./data-warning-signs";
import directionSigns from "./direction-signs";
import givingOrderSigns from "./giving-order-signs";
import informationSigns from "./information-signs";
import roadWorksSigns from "./road-work-signs";

export const DATA_TRAFFIC_SIGNS = {
    directionSigns,
    givingOrderSigns,
    informationSigns,
    roadWorksSigns,
    warningSigns
};
