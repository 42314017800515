import { ITopic } from "@drivingo/models";

export const DATA_TOPICS: ITopic[] = [
    {
        code: 'alertness',
        name: 'Alertness',
        count: 26
    },
    {
        code: 'attitude',
        name: 'Attitude',
        count: 39
    },
    {
        code: 'safety_and_your_vehicle',
        name: 'Safety and your vehicle',
        count: 78
    },
    {
        code: 'safety_margins',
        name: 'Safety margins',
        count: 33
    },
    {
        code: 'hazard_awareness',
        name: 'Hazard awareness',
        count: 78
    },
    {
        code: 'vulnerable_road_users',
        name: 'Vulnerable road users',
        count: 67
    },
    {
        code: 'other_types_of_vehicle',
        name: 'Other types of vehicle',
        count: 22
    },
    {
        code: 'vehicle_handling',
        name: 'Vehicle handling',
        count: 44
    },
    {
        code: 'motorway_rules',
        name: 'Motorway rules',
        count: 55
    },
    {
        code: 'rules_of_the_road',
        name: 'Rules of the road',
        count: 67
    },
    {
        code: 'road_and_traffic_signs',
        name: 'Road and traffic signs',
        count: 133
    },
    {
        code: 'essential_documents',
        name: 'Essential documents',
        count: 28
    },
    {
        code: 'incidents-accidents_and_emergencies',
        name: 'Incidents, accidents and emergencies',
        count: 47
    },
    {
        code: 'vehicle_loading',
        name: 'Vehicle loading',
        count: 12
    },
    {
        code: 'videos',
        name: 'Video Questions',
        count: 27
    },
];
