import { ITrafficSign } from "@drivingo/models";

const informationSigns: ITrafficSign[] = [
    { imgPath: 'information-signs/Information-sign-entrance-controlled-parking-zone.jpg', desc: "Entrance to controlled parking zone" },
    { imgPath: 'information-signs/Information-sign-entrance-congestion-zone.jpg', desc: "Entrance to congestion charging zone" },
    { imgPath: 'information-signs/Information-sign-end-controlled-parking-zone.jpg', desc: "End of controlled parking zone" },
    { imgPath: 'information-signs/Information-sign-advance-warning-restriction-ahead.jpg', desc: "Advance warning of restriction or prohibition ahead" },
    { imgPath: 'information-signs/Information-sign-parking-place-solo-motorcycles.jpg', desc: "Parking place for solo motorcycles" },
    { imgPath: 'information-signs/Information-sign-with-flow-bus-lane-ahead.jpg', desc: "With-flow bus lane ahead which pedal cycles and taxis may also use" },
    { imgPath: 'information-signs/Information-sign-lane-for-hov.jpg', desc: "Lane designated for use by high occupancy vehicles (HOV) - see rule 142" },
    { imgPath: 'information-signs/Information-sign-vehicles-permitted-HOV-lane.jpg', desc: "Vehicles permitted to use an HOV lane ahead" },
    { imgPath: 'information-signs/Information-sign-motorway-end.jpg', desc: "End of motorway" },
    { imgPath: 'information-signs/Information-sign-start-motorway.jpg', desc: "Start of motorway and point from which motorway regulations apply" },
    { imgPath: 'information-signs/Information-sign-appropriate-traffic-lanes-junction.jpg', desc: "Appropriate traffic lanes at junction ahead" },
    { imgPath: 'information-signs/Information-sign-traffic-on-carriageway-priority.jpg', desc: "Traffic on the main carriageway coming from right has priority over joining traffic" },
    { imgPath: 'information-signs/Information-sign-addtional-traffic-joining-left.jpg', desc: "Additional traffic joining from left ahead. Traffic on main carriageway has priority over joining traffic from right hand lane of slip road" },
    { imgPath: 'information-signs/Information-sign-traffic-joining-right-hand-slip-road.jpg', desc: "Traffic in right hand lane of slip road joining the main carriageway has priority over left hand lane" },
    { imgPath: 'information-signs/Information-sign-motorway-exit-countdown-markers.jpg', desc: "‘Countdown’ markers at exit from motorway (each bar represents 100 yards to the exit). Green-backed markers may be used on primary routes and white-backed markers with black bars on other routes. At approaches to concealed level crossings white-backed markers with red bars may be used., desc: ough these will be erected at equal distances the bars do not represent 100 yard intervals." },
    { imgPath: 'information-signs/Information-sign-motorway-service.jpg', desc: "Motorway service area sign showing the operator’s name" },
    { imgPath: 'information-signs/Information-sign-traffic-priority-over-oncoming-vehicles.jpg', desc: "Traffic has priority over oncoming vehicles" },
    { imgPath: 'information-signs/Information-sign-hospital-a-and-e.jpg', desc: "Hospital ahead with Accident and Emergency facilities" },
    { imgPath: 'information-signs/Information-sign-tourist-info-point.jpg', desc: "Tourist information point" },
    { imgPath: 'information-signs/Information-sign-no-through-road.jpg', desc: "No through road for vehicles" },
    { imgPath: 'information-signs/Information-sign-recommended-route-cycles.jpg', desc: "Recommended route for pedal cycles" },
    { imgPath: 'information-signs/Information-sign-home-zone-entry.jpg', desc: "Home Zone Entry" },
    { imgPath: 'information-signs/Information-sign-camera-area.jpg', desc: "Area in which cameras are used to enforce traffic regulations" },
    { imgPath: 'information-signs/Information-sign-bus-lane-road-junction-ahead.jpg', desc: "Bus lane on road at junction ahead" }
];

export default informationSigns;
