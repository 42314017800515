import { IHighwayCode } from "@drivingo/models";

const signTopics: IHighwayCode[] = [
    {
        id: "f2a7ef41-7e4e-4b4b-a292-32f2d8990164",
        title: "Light signals controlling traffic",
        footNote: "",
        api_url: "https://www.gov.uk/api/content/guidance/the-highway-code/light-signals-controlling-traffic"
    },
    {
        id: "e4fea682-4f35-4f82-9910-484c86cb7ea9",
        title: "Signals to other road users",
        footNote: "",
        api_url: "https://www.gov.uk/api/content/guidance/the-highway-code/signals-to-other-road-users"
    },
    {
        id: "79b9d76a-be07-4e73-9f67-bf788ffec746",
        title: "Signals by authorised persons",
        footNote: "",
        api_url: "https://www.gov.uk/api/content/guidance/the-highway-code/signals-by-authorised-persons"
    },
    {
        id: "c23fc28a-0b83-432e-a493-70ec6b5200c7",
        title: "Traffic signs",
        footNote: "",
        api_url: "https://www.gov.uk/api/content/guidance/the-highway-code/traffic-signs"
    },
    {
        id: "d4e8f174-4608-430e-93d8-61271707b2e9",
        title: "Road markings",
        footNote: "",
        api_url: "https://www.gov.uk/api/content/guidance/the-highway-code/road-markings"
    },
    {
        id: "639e65ff-0140-40f5-b3d6-39aaa213ed5d",
        title: "Vehicle markings",
        footNote: "",
        api_url: "https://www.gov.uk/api/content/guidance/the-highway-code/vehicle-markings"
    }
];

export default signTopics;
