import { ITrafficSign } from "@drivingo/models";

const givingOrderSigns: ITrafficSign[] = [
    { imgPath: 'giving-order-signs/sign-giving-order-entry-20-zone.jpg', desc: `Entry to 20 mph zone` },
    { imgPath: 'giving-order-signs/sign-giving-order-end-20-zonev2.jpg', desc: `End of 20 mph zone` },
    { imgPath: 'giving-order-signs/sign-giving-order-maximum-speed.jpg', desc: `Maximum speed` },
    { imgPath: 'giving-order-signs/sign-giving-order-national-speed-limit.jpg', desc: `National speed limit applies` },
    { imgPath: 'giving-order-signs/sign-giving-order-school-crossing-patrol.jpg', desc: `School crossing patrol` },
    { imgPath: 'giving-order-signs/sign-giving-order-stop-give-way.jpg', desc: `Stop and give way` },
    { imgPath: 'giving-order-signs/sign-giving-order-give-way.jpg', desc: `Give way to traffic on major road` },
    { imgPath: 'giving-order-signs/sign-giving-order-manually-stop.jpg', desc: `Manually operated temporary STOP and GO signs` },
    { imgPath: 'giving-order-signs/sign-giving-order-manually-go.jpg', desc: `Manually operated temporary STOP and GO signs` },
    { imgPath: 'giving-order-signs/sign-giving-order-no-entry-vehicular-traffic.jpg', desc: `No entry for vehicular traffic` },
    { imgPath: 'giving-order-signs/sign-giving-order-no-vehicles.jpg', desc: `No vehicles except bicycles being pushed` },
    { imgPath: 'giving-order-signs/sign-giving-order-no-cycling.jpg', desc: `No cycling` },
    { imgPath: 'giving-order-signs/sign-giving-order-no-motor-vehicles.jpg', desc: `No motor vehicles` },
    { imgPath: 'giving-order-signs/sign-giving-order-no-buses.jpg', desc: `No buses (over 8 passenger seats` },
    { imgPath: 'giving-order-signs/sign-giving-order-no-overtaking.jpg', desc: `No overtaking` },
    { imgPath: 'giving-order-signs/sign-giving-order-no-towed-caravans.jpg', desc: `No towed caravans` },
    { imgPath: 'giving-order-signs/sign-giving-order-no-vehicles-carry-explosives.jpg', desc: `No vehicles carrying explosives` },
    { imgPath: 'giving-order-signs/sign-giving-order-no-vehicle-combination-length.jpg', desc: `No vehicle or combination of vehicles over length shown` },
    { imgPath: 'giving-order-signs/sign-giving-order-no-vehicles-over-height.jpg', desc: `No vehicles over height shown` },
    { imgPath: 'giving-order-signs/sign-giving-order-no-vehicle-width.jpg', desc: `No vehicles over width shown` },
    { imgPath: 'giving-order-signs/sign-giving-order-give-priority-vehicles.jpg', desc: `Give priority to vehicles from opposite direction` },
    { imgPath: 'giving-order-signs/sign-giving-order-no-right-turn.jpg', desc: `No right turn` },
    { imgPath: 'giving-order-signs/sign-giving-order-no-left-turn.jpg', desc: `No left turn` },
    { imgPath: 'giving-order-signs/sign-giving-order-no-u-turn.jpg', desc: `No U-turns` },
    { imgPath: 'giving-order-signs/sign-giving-order-no-goods-vehicle-over-weight.jpg', desc: `No goods vehicles over maximum gross weight shown (in tonnes) except for loading and unloading` },
    { imgPath: 'giving-order-signs/sign-giving-order-no-vehicles-max-gross-weight.jpg', desc: `No vehicles over maximum gross weight shown (in tonnes` },
    { imgPath: 'giving-order-signs/sign-giving-order-park-restrict-permit-holders.jpg', desc: `Parking restricted to permit holders` },
    { imgPath: 'giving-order-signs/sign-giving-order-red-route.jpg', desc: `No stopping during period indicated except for buses` },
    { imgPath: 'giving-order-signs/sign-giving-order-no-stopping-times.jpg', desc: `No stopping during times shown except for as long as necessary to set down or pick up passengers` },
    { imgPath: 'giving-order-signs/sign-giving-order-no-waiting.jpg', desc: `No waiting` },
    { imgPath: 'giving-order-signs/sign-giving-order-no-stopping.jpg', desc: `No stopping (Clearway)` },
    { imgPath: 'giving-order-signs/sign-giving-order-ahead-only.jpg', desc: `Ahead only` },
    { imgPath: 'giving-order-signs/sign-giving-order-turn-left-ahead.jpg', desc: `Turn left ahead (right if symbol reversed` },
    { imgPath: 'giving-order-signs/sign-giving-order-turn-left.jpg', desc: `Turn left (right if symbol reversed` },
    { imgPath: 'giving-order-signs/sign-giving-order-keep-left.jpg', desc: `Keep left (right if symbol reversed` },
    { imgPath: 'giving-order-signs/sign-giving-order-vehicle-pass-either-side.jpg', desc: `Vehicles may pass either side to reach same destination` },
    { imgPath: 'giving-order-signs/sign-giving-order-mini-roundabout.jpg', desc: `Mini-roundabout (roundabout circulation - give way to vehicles from the immediate right` },
    { imgPath: 'giving-order-signs/sign-giving-order-route-pedal-cycles-only.jpg', desc: `Route to be used by pedal cycles only` },
    { imgPath: 'giving-order-signs/sign-giving-order-segregated-cycle-pedestrian-route.jpg', desc: `Segregated pedal cycle and pedestrian route` },
    { imgPath: 'giving-order-signs/sign-giving-order-minimum-speed.jpg', desc: `Minimum speed` },
    { imgPath: 'giving-order-signs/sign-giving-order-minimum-speed-end.jpg', desc: `End of minimum speed` },
    { imgPath: 'giving-order-signs/sign-giving-order-buses-cycles-only.jpg', desc: `Buses and cycles only` },
    { imgPath: 'giving-order-signs/sign-giving-order-trams-only.jpg', desc: `Trams only` },
    { imgPath: 'giving-order-signs/sign-giving-order-pedestrian-crossing-tramway.jpg', desc: `Pedestrian crossing point over tramway` },
    { imgPath: 'giving-order-signs/sign-giving-order-one-way-traffic.jpg', desc: `One-way traffic (note: compare circular ‘Ahead only’ sign` },
    { imgPath: 'giving-order-signs/sign-giving-order-with-flow-bus-cycle-lane.jpg', desc: `With-flow bus and cycle lane` },
    { imgPath: 'giving-order-signs/sign-giving-order-contra-flow-bus-lane.jpg', desc: `Contra-flow bus lane` },
    { imgPath: 'giving-order-signs/sign-giving-order-with-flow-pedal-cycle-lane.jpg', desc: `With-flow pedal cycle lane` }
];

export default givingOrderSigns;
